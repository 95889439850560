<template>
    <div class="add-polt">
        <van-form class="form-container" @submit="onSubmit" :disabled="isAdmin">
            <van-field
                v-model="plotInfo.massifName"
                name="massifName"
                label="地块名称"
                required
                placeholder="请输入地块名称"
                :rules="[{ required: true}]"
            />
            <van-field
                v-model="plotInfo.parkName"
                name="parkName"
                label="园区"
                readonly
                placeholder="请选择园区"
                @click="showSelectPark"
                required
                :rules="[{ required: true}]"
            />
            <van-field
                v-model="plotInfo.address"
                name="address"
                readonly
                label="地理位置"
                placeholder="请选择地理位置"
                @click="showAddressSelect"
                required
                :rules="[{ required: true}]"
            />
            <van-field
                v-model="plotInfo.contacts"
                name="contacts"
                label="联系人"
                :placeholder="!isAdmin ? '请输入联系人' : ''"
            />
            <van-field
                v-model="plotInfo.phone"
                name="phone"
                label="联系电话"
                :placeholder="!isAdmin ? '请正确手机号码' : ''"
                
            />
            <van-field
                v-model="plotInfo.crop"
                name="crop"
                label="作物名称"
                placeholder="请输入作物名称"
                required
                :rules="[{ required: true}]"
            />
            <van-field
                v-model="plotInfo.predictYield"
                name="predictYield"
                label="预估产量"
                placeholder="请输入预估产量"
                :rules="[{ required: false}]"
            />
            <van-field
                v-model="plotInfo.description"
                rows="2"
                autosize
                name="description"
                label="作物描述"
                type="textarea"
                maxlength="100"
                :placeholder="!isAdmin ? '请输入作物描述' : ''"
                show-word-limit
            /> 
            <van-field
                v-model="plotInfo.remark"
                name="remark"
                label="备注"
                :placeholder="!isAdmin ? '请输入备注' : ''"
            />
            <van-field name="switch" label="是否启用" v-if="!isAdd">
                <template #input>
                    <van-switch v-model="switchChecked" size="20" :disabled="isAdmin" />
                </template>
            </van-field>
            <van-uploader v-model="fileList" :after-read="afterRead" :disabled="fileList.length > 0" v-if="!isAdmin"/>
           <!--  <van-field name="switch" label="是否启用">
                <template #input>
                    <van-switch v-model="switchChecked" size="20" />
                </template>
            </van-field> -->
            <div class="form-bottom">
                <van-button round block type="info" native-type="submit" v-show="!isAdmin">保存</van-button>
            </div>
        </van-form>
        <!-- 园区选择 -->
        <van-action-sheet v-model="show" :actions="actions" @select="select" />
        <!-- 地理位置选择 -->
        <van-popup v-model="showAddress" >
            <div class="map-container">
                <div class="map" id="map"></div>
                <!-- <input id="mapSearch" class="map-search"/> -->
                <van-search
                    id="mapSearch"
                    class="map-search"
                    v-model="mapSearch"
                    input-align="center"
                    shape="round"
                    placeholder="请输入搜索地址"
                />
            </div>
            <div class="btn-save">
                <van-button round block type="info" @click="closeAddress">关闭</van-button>
            </div>
        </van-popup>
    </div>
</template>

<script>
import {listTreeOrg } from '@/api/plotManagement';
import { addMassif, modifyMassif, uploadImg} from '@/api/plotManagement';
import {getToken,getOrgId,getUserType } from '@/utils/cookie'
export default {
    data() {
        return {
            isAdd: true,//判断是新增还是编辑
            plotInfo: {
                massifName: '', // 地块名称
                parkName: '',//园区名称
                parkId: '', //园区id
                address: '', // 地块地址
                menuPid: '', // 父级地块ID
                contacts: '', // 联系人
                phone: '', // 手机号
                remark: '', // 描述
                describe: '',//经纬度
                updater: '1',//是否启用
                crop: '',//作物名称
                description: '',//作物描述
                cropUrl: '', //作物图片地址
            },
            switchChecked: true,//是否启用
            show: false,//是否显示选择园区
            showAddress: false,//是否选择地理位置
            activeId: 1,
            activeIndex: 1,
            actions: [],
            map: null,//地图
            mapSearch: '',
            isAdmin: true,//是否是管理员
            fileList: [// 上传图片数组
                // { url: 'https://img01.yzcdn.cn/vant/leaf.jpg' },
                // Uploader 根据文件后缀来判断是否为图片文件
                // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
                // { url: 'https://cloud-image', isImage: true },
            ],
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.isAdmin = getUserType() == 1 ? true : false;
        });
        this.listAllPark()
    },
    methods: {
        onSubmit(val) {
            if (this.isAdd) {
                addMassif(this.plotInfo).then(res => {
                    if (res.code == 200) {
                        this.$notify({type: 'success', message: '新增成功'});
                        this.$router.push({path: '/plotManagement'})
                    }
                });
            } else {
                this.plotInfo.updater = this.switchChecked ? 1 : 0;
                modifyMassif(this.plotInfo).then(res => {
                    if (res.code == 200) {
                        this.$notify({type: 'success', message: '编辑成功'});
                        this.$router.push({path: '/plotManagement'})
                    }
                });
            }
            
        },
        onCancel() {

        },
        select(val) {
            this.plotInfo.parkName = val.name;
            this.plotInfo.parkId = val.orgId;
            this.menuPid = val.orgPid;
            this.show = false;
        },
        /** 查询所有园区 */
        listAllPark() {
            listTreeOrg({
                orgId: getOrgId(),//当前查询页面
            }).then( res => {
                if( res.code === 200 ) {
                    res.data.forEach((item, index) => {
                        if (item) {
                            item.name = item.orgName;
                        }
                    });
                    this.actions = res.data || [];
                }
            });
        },
        /** 显示选择园区 */
        showSelectPark() {
            if (!this.isAdmin) {
                this.show = true;
            }
        },
        /** 显示选择地址 */
        showAddressSelect() {
            if (!this.isAdmin) {
                this.showAddress = true;
                this.$nextTick(() => {
                    this.initMap();
                })
            }
           
        },
        /** 初始化地图 */
        initMap() {
            this.map = new BMap.Map('map');
            this.map.centerAndZoom(new BMap.Point(116.3964,39.9093), 10);
            this.addSearchInput();
        },
        /** 地图添加搜索功能 */
        addSearchInput() {
            let that = this;
            let ac = new BMap.Autocomplete(    //建立一个自动完成的对象
                {'input' : 'mapSearch'
                ,'location' : map,
                /* onSearchComplete: function(data) {
                    // console.log(data)
                } */
            });
            ac.addEventListener("onhighlight", function(e) {  
                let str = "";
                let _value = e.fromitem.value;
                let value = "";
                if (e.fromitem.index > -1) {
                    value = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
                }
                str = "FromItem<br />index = " + e.fromitem.index + "<br />value = " + value;
                value = "";
                if (e.toitem.index > -1) {
                    _value = e.toitem.value;
                    value = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
                }
                str += "<br />ToItem<br />index = " + e.toitem.index + "<br />value = " + value;
                document.getElementById('mapSearch').innerHTML = str;
            });
            let myValue;
            ac.addEventListener("onconfirm", function(e) {    //鼠标点击下拉列表后的事件
                let _value = e.item.value;
                    myValue = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
                document.getElementById('mapSearch').innerHTML ="onconfirm<br />index = " + e.item.index + "<br />myValue = " + myValue;
                that.mapSearch = myValue;
                that.plotInfo.address = myValue;
                that._setPlace(myValue);
            });
        },
        /** 百度地图定位 */
        _setPlace(value) {
            let that = this;
            this.map.clearOverlays();
            function myFun(){
                let pp = local.getResults().getPoi(0).point;    //获取第一个智能搜索的结果
                that.map.centerAndZoom(pp, 14);
                let myIcon = new BMap.Icon(require('@/assets/images/location.svg'),new BMap.Size(40,50));
                let marker = new BMap.Marker(pp,{icon:myIcon});
                that.plotInfo.describe = JSON.stringify(pp);
                that.map.addOverlay(marker);
                // that.marker = that.createMarker(pp,{icon:{size:[40,50],anchor:[0,0]}},null,false,null,null,null,true,true);
                // cb({point:pp,search:'search',address:value});
            }
            let local = new BMap.LocalSearch(that.map, { //智能搜索
                onSearchComplete: myFun
            });
            local.search(value);
        },
        /** 关闭选择地址弹窗 */
        closeAddress() {
            this.showAddress = false;
        },
        /** 上传文件后 */
        afterRead(file) {
            // console.log(file)
            let data = new FormData();
            data.append("file", file.file);
            uploadImg(data).then( res => {
                if( res.code === 200 ) {
                    this.plotInfo.cropUrl = res.data[0];
                }
            });
        }
    },
    beforeRouteEnter(to, from, next) {
        if (to.params.massifId) {
            next(vm => {
                let type = getUserType() == 1 ? true : false;
                vm.plotInfo = to.params;
                vm.fileList.push({ url: to.params.cropUrl, isImage: true });
                if (type) {
                    document.title = '地块详情';
                } else {
                    let doms = document.getElementsByTagName('input');
                    doms.forEach(item => {
                        item.setAttribute('placeholder','');
                    });
                    document.title = '编辑地块';
                }
                vm.switchChecked =  to.params.updater == 1 ? true : false;
                vm.isAdd = false;
            })
        } else {
            next(vm => {
                vm.isAdd = true;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .add-polt {
        height: 100%;
        background-color: #fff;
        .form-container {
            padding: 8px;
            .form-bottom {
                position: fixed;
                left: 12px;
                bottom: 10px;
                width: 346px;
            }
        }
    }
</style>
<style lang="scss">
    .add-polt {
        .van-popup {
            width: 100%;
            height: 100%;
            .map-container {
                width: 100%;
                height: 600px;
                margin: 0 auto;
                border-radius: 8px;
                .map {
                    width: 100%;
                    height: 100%;
                    // margin-top: 30px;
                    border-radius: 8px;
                }
            }
            .van-popup__close-icon {
                top: 5px;
                right: 5px;
                color: #a7a9af;
            }
            .map-search {
                position: absolute;
                top: 10px;
                left: 10px;
                width: 346px;
            }
            .van-search {
                background-color: transparent;
            }
        }
        .btn-save {
            position: fixed;
            bottom: 10px;
            left: 12px;
            width: 346px;
        }
    }
    .tangram-suggestion-main {
        left: 25px !important;
        width: 310px !important;
        z-index: 999999;
    }
</style>